.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: fit-content;
  padding: 0;
  width: 0;
}


.bg-cover{
  width: 100%;
  max-width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
}

.image-overlay {
  width: 100%;
  height: 100vh;

  background-color: rgba(1, 27, 26, 0.77);

  display: block;
  justify-content: center;
  align-items: center;
  
}


.form-el{
  background-color: rgba(0, 0, 0, 0.717);

}

.home-bar {
  background-color: rgba(0, 0, 0, 0.213);
}